import { dateTimeString, sorterFn } from '../utils';

const dateTimeRender = (dateMilliseconds) => dateTimeString(dateMilliseconds);

const schema = {
  nombreArchivo: {
    type: "text",
    title: "Nombre de archivo",
    dataIndex: "nombreArchivo",
    sorter: (prev, curr) => sorterFn(prev, curr, "nombreArchivo"),
  },
  procesoOk: {
    type: "text",
    title: "Proceso OK",
    dataIndex: "procesoOk",
    sorter: (prev, curr) => sorterFn(prev, curr, "procesoOk"),
  },
  descripcion: {
    type: "text",
    title: "Descripción",
    dataIndex: "descripcion",
    sorter: (prev, curr) => sorterFn(prev, curr, "descripcion"),
  },
  fechaInicio: {
    type: "text",
    title: "Fecha Inicio",
    dataIndex: "fechaInicio",
    render: dateTimeRender,
    sorter: (prev, curr) => sorterFn(prev, curr, "fechaInicio"),
  },
  fechaFin: {
    type: "text",
    title: "Fecha Fin",
    dataIndex: "fechaFin",
    render: dateTimeRender,
    sorter: (prev, curr) => sorterFn(prev, curr, "fechaFin"),
  },
};

export { schema };