import React, {memo} from 'react';
import '../assets/App.css'
import logoColombia from '../assets/img/GOBIERNO-DE-COLOMBIA-2020.png';
import logoIcontec from '../assets/img/logo-icontec.png';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Footer() {
    return (
        <div className="px-2 sm:px-6 lg:px-8 -mt-8 bg-grisDos">
            <div className="flex items-center h-1/6">

                <div className="flex-shrink-0 flex items-center w-1/3">
                    <img className="block lg:hidden h-20 w-auto" src={logoIcontec}/>
                </div>

                <div className="flex-1 flex  justify-center w-1/3">
                    <div className="text-rosa text-center md:mr-4">&copy; 2021 analIsA</div>
                </div>

                <div class="flex justify-end flex-row space-x-2 space-x w-1/3">
                    <img className="block lg:hidden h-16 w-auto" src={logoColombia}/>
                </div>
            </div>
        </div>
    )
}

export default memo(Footer)


