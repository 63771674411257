import { Tabs } from 'antd';

const { TabPane } = Tabs;

const TabSwitcher = ({ tabs, defaultActiveKey = "1" }) => (
  <Tabs centered {...defaultActiveKey}>
    {tabs.map((tab, i) => (
      <TabPane tab={tab.name} key={i}>
        {tab.component}
      </TabPane>
    ))}
  </Tabs>
);

export default TabSwitcher;
