import React, {memo} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import '../assets/App.css'
import logo from '../assets/img/logindashboard.png';
import logoAnalisa from '../assets/img/_analisa3.png';
import facebook from '../assets/iconos/facebook.png';
import youtube from '../assets/iconos/youtube.png';
import twwiter from '../assets/iconos/twitter.png';
import asistente from '../assets/iconos/logo_sofia.png';
import {useUsuario} from "../Context/usuario-context";
import {getIdF5} from "../Helpers/auth-helpers";
import {PrivateRoutesConfig, PrivateRoutesConfigCargaOCR, PrivateRoutesConfigConsulta, PrivateRoutesConfigConsultaCarga} from "../configuracion";



function Nav() {
    const {login, logout, loginExitoso, usuario} = useUsuario();
    return (
        <>
            {usuario && <LoginRoutes/>}
        </>
    )
}

/**
 *
 * @returns {JSX.Element|*|ValidationOptions.unknown.logout}
 * @constructor
 */
function LoginRoutes() {
    let allowedRoutes = [];
    const {login, logout, loginExitoso, usuario} = useUsuario();
    const history = useHistory();
    
    if (usuario === '1') {  //Admin
        allowedRoutes = PrivateRoutesConfig;
        if(!(getIdF5() === 'F5_KEY')){
            history.push('/buscar');
        }

    } else if (usuario === '2') { //Solo Consulta
        allowedRoutes = PrivateRoutesConfigConsulta;
        if(!(getIdF5() === 'F5_KEY')){
            history.push('/buscar');
        }
    } else if (usuario === '3') { //Solo Carga
        allowedRoutes = PrivateRoutesConfigCargaOCR;
        if(!(getIdF5() === 'F5_KEY')){
            history.push('/cargar');
        }
    } else if (usuario === '4') { //Consulta y Carga
        allowedRoutes = PrivateRoutesConfigConsultaCarga;
        if(!(getIdF5() === 'F5_KEY')){
            history.push('/buscar');
        }
    } else {
        history.push('/');
        return logout;
    }
    return (
        <>
            <nav className="bg-grisDos fixed inset-x-0 top-0 left-0 py-1 navbar">
                <div className="px-2 sm:px-6 lg:px-8 -mt-8">
                    <div className="flex items-center h-1/6">
                        <div className="flex-shrink-0 flex items-center w-1/3">
                            <img className="block lg:hidden h-20 w-auto"
                                 src={logo}/>
                        </div>
                        <div className="flex-1 flex  justify-center w-1/3 p-2">
                            <img className="block lg:hidden h-6/6 w-64 -ml-26"
                                 src={logoAnalisa}/>
                        </div>
                        <div class="flex justify-end flex-row space-x-2 space-x w-1/3">
                            <div>
                                <NavLink activeClassName="false" to={{pathname: "https://www.facebook.com/CRCCol"}}
                                         target="_blank">
                                    <img src={facebook} alt="Facebook CRC"/>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink activeClassName="false" to={{pathname: "https://twitter.com/CRCCol"}}
                                         target="_blank">
                                    <img src={twwiter} alt="Twitter CRC"/>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink activeClassName="false" to={{pathname: "https://www.youtube.com/user/CRCCol"}}
                                         target="_blank">
                                    <img src={youtube} alt="Youtube CRC"/>
                                </NavLink>
                            </div>
                            <div>
                                <NavLink activeClassName="false"
                                         to={{pathname: "https://www.crcom.gov.co/es/pagina/inicio"}} target="_blank">
                                    <img src={asistente} alt="Asistente Virtual"/>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="bg-grisDos fixed inset-x-0 top-25 left-0 py-1 navbar">
                <div className="px-2 sm:px-6 lg:px-8">
                    <div className="flex items-center h-1/6">
                        <div className="flex-1 flex  justify-center">

                            <div className="hidden sm:block ml-40">

                                <div className="space-x-8">
                                    {allowedRoutes.map(({path, title}) => (
                                        <NavLink key={path} activeClassName="active text-white"
                                                 className="text-black hover:bg-rosa hover:text-white text-sm font-semibold px-3 py-2 rounded-md"
                                                 to={path}>
                                            {title}
                                        </NavLink>
                                    ))}
                                </div>

                            </div>
                        </div>
                        <div
                            className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">


                            <div className="ml-10 relative">
                                <div>
                                    <button type="button" className="ml-8 h-5  text-white py-1 ">
                                        <NavLink activeClassName="active" to="/"
                                                 className="ml-8 h-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 text-base text-white bg-rosa hover:bg-azul hover:text-white hover:border-azul">

                                            <BotonLogout logout={logout}></BotonLogout>
                                        </NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default memo(Nav)

/**
 *
 * @param logout
 * @returns {JSX.Element}
 * @constructor
 */
function BotonLogout({logout}) {
    return (
        <button onClick={logout}>
            Salir
        </button>
    )
}




