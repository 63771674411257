import React, {useEffect, useMemo, useState} from 'react';
import {deleteToken, getToken, setToken , setIdF5} from "../Helpers/auth-helpers";
import Axios from "axios";

/**
 *
 * @type {React.Context<unknown>}
 */
const UsuarioContext = React.createContext();
/**
 * Constantes
 */
const ROL_CONSULTA = "ROLE_CONSULTA";
const ROL_CARGA = "ROLE_CARGA";
const ROL_ADMIN = "ROLE_ADMIN";
/**
 * Sí, el rest nos responde los dos roles (ROLE_CONSULTA y ROLE_CARGA )
 * le asignamos todos los módulos (Buscar, Cargar, OCR, Historia)
 * @type {number}
 */
const NUM_ROLES = 2;

export function UsuarioProvider(props) {

    /*
    Nuevas Variables
     */
    //No sabemos si hay un usuario autenticado
    const [usuario, setUsuario] = useState(null);

    //saber esl estado del usuario
    const [cargandoUsuario, setCargandoUsuario] = useState(true);


    //saber si el ingreso del usuario es exitoso
    const [loginExitoso, setLoginExitoso] = useState(false);

    //saber si el ingreso del usuario es exitoso
    const [usuarioJWT, setUsuarioJWT] = useState([]);


    /**
     * funcion a cambiar con el token
     * antes -> api/public/login
     * ahora-> /api/consultar/temas
     */
    useEffect(() => {

        async function cargarUsuario() {
            //validamos si existe un token
            if (!(getToken())) {
                setCargandoUsuario(false);
                return;
            }
            try {

                const datos = {
                    'jwt': getToken(),
                }
                const {data: usuarioJWT} = await Axios.post('api/public/login/jwt', datos);
                setUsuarioJWT(usuarioJWT);
                if (usuarioJWT.loginExitoso === true && usuarioJWT.roles.length > 0) {
                    if (usuarioJWT.roles.length === NUM_ROLES) {
                        setUsuario('4');
                        setLoginExitoso(true);
                    } else {
                        if (usuarioJWT.roles[0] === ROL_CONSULTA) {
                            setUsuario('2');
                            setLoginExitoso(true);
                        } else if (usuarioJWT.roles[0] === ROL_CARGA) {
                            setUsuario('3');
                            setLoginExitoso(true);
                        } else if (usuarioJWT.roles[0] === ROL_ADMIN) {
                            setUsuario('1');
                            setLoginExitoso(true);
                        } else {
                            setUsuario('0');
                            setLoginExitoso(false);
                        }
                    }
                } else {
                    setUsuario('0');
                    setLoginExitoso(false);
                }
                setCargandoUsuario(false);
            } catch (error) {
                setUsuario('0');
                setLoginExitoso(false);
                deleteToken();
                window.location = '/';

            }

        }

        cargarUsuario();

    }, []);


    /**
     *  data.usuario
     *  data.token
     * @param email
     * @param password
     * @returns {Promise<void>}
     */
    async function login(user, password) {
        const {data} = await Axios.post('api/public/login', {
                user,
                password,

            }
        );


        if (data.loginExitoso === true && data.roles.length > 0) {
            if (data.roles.length === NUM_ROLES) {
                setUsuario('4');
                setLoginExitoso(true);
            } else {
                if (data.roles[0] === ROL_CONSULTA) {
                    setUsuario('2');
                    setLoginExitoso(true);
                } else if (data.roles[0] === ROL_CARGA) {
                    setUsuario('3');
                    setLoginExitoso(true);
                } else if (data.roles[0] === ROL_ADMIN) {
                    setUsuario('1');
                    setLoginExitoso(true);
                } else {
                    setUsuario('0');
                    setLoginExitoso(false);
                }
            }
        } else {
            setUsuario('0');
            setLoginExitoso(false);
        }

        /**
         * Asiganomos el Token al sistema para consultas.
         */
        setToken(data.jwt);
        setIdF5('F5_KEY')

    }

    /**
     * Funcion para salir de la aplicación
     */
    function logout() {
        setUsuario(null);
        deleteToken();
    }


    /**
     *
     * @type {{logout: logout, cargandoUsuario: boolean, loginExitoso: boolean, usuario: unknown, login: function(*, *): Promise<void>}}
     */
    const value = useMemo(() => {
        return ({
            usuario,
            cargandoUsuario,
            logout,
            login,
            loginExitoso
        })
    }, [usuario])

    return <UsuarioContext.Provider value={value}{...props} />
}

/**
 * Nuevo Hook que provee a cualquier componente un usuario
 */
export function useUsuario() {

    const context = React.useContext(UsuarioContext);

    if (!context) {
        throw new Error('useUsuario debe estar dentro del proveedor UsuarioContext')
    }
    return context;


}

