import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import printPdf from "../../services/adminPdf";

import { calculateDuration, dateString, dateTimeString, notify } from '../../utils';

import { schema } from '../../models/Estadistica';

import {
  Button,
  DatePicker,
  PageHeader,
  Space,
  Table,
} from 'antd';

const AdminEstadisticas = () => {

  const [estadisticas, setEstadisticas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(moment().subtract(7, 'd'));
  const [endDate, setEndDate] = useState(moment());
  const [tableDates, setTableDates] = useState();
  const [currentSorter, setCurrentSorter] = useState(() => {});

  const handleFechaDesdeChange = (_, date) => {
    setEndDate(undefined);
    setStartDate(moment(date));
  };

  const handleSearch = () => {
    cargarEstadisticas(startDate, endDate);
  };

  const cargarEstadisticas = async (
    startDate,
    endDate
  ) => {

    if (!(startDate && endDate)) {
      notify("error", "Formato de fechas inválido.");

      return;
    }

    setLoading(true);
    try {
      let _startDate = dateString(startDate);
      let _endDate = dateString(endDate.add(1,'d'));

      const response = await axios.get(`api/stats/stats?start_date=${_startDate}&end_date=${_endDate}`);
      
      let _resp = response.data.sort(function(a, b) {
        if(a.fechaInicial > b.fechaInicial){
          return -1
        }
      });
    
      
      setEstadisticas(_resp || []);
    
      if (!response.data.length) {
        notify("warning", "Sin resultados");
      } else {
        setTableDates({
          startDate: dateString(startDate),
          endDate: dateString(endDate),
        });
      }
    } catch (err) {
      console.error(err);
      notify("error", "No se pudieron obtener las estadísticas.")
    }
    setLoading(false);
  };

  const handlePrint = () => {

    printPdf({
      data: estadisticas.sort(currentSorter).map(estadistica => ({
        "Usuario": estadistica.usuario,
        "Estado": estadistica.estado,
        "Tipo": estadistica.tipo,
        "Fecha inicio": dateTimeString(estadistica.fechaInicial),
        "Fecha fin": dateTimeString(estadistica.fechaFinal),
        "Tiempo": calculateDuration(estadistica.fechaInicial, estadistica.fechaFinal),
        "Detalle": estadistica.detalle,
      })),
      functionName: "Estadísticas",
      criteria: {
        "Fecha inicio": tableDates.startDate,
        "Fecha fin": tableDates.endDate
      }
    });
  };

  useEffect(() => {
    cargarEstadisticas(startDate, endDate);
  }, []);

  const columns = [
    ...Object.values(schema),
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Space>
            <>
              Fecha inicio:
              <DatePicker
                allowClear={false}
                value={startDate}
                onChange={handleFechaDesdeChange}
                format={'YYYY-MM-DD'}
                placeholder='Fecha - Hora'
                rules={[{ required: true, message: 'Fecha desde es requerida.' }]}
              />
              Fecha fin:
              <DatePicker
                allowClear={false}
                value={endDate}
                onChange={(_, date) => setEndDate(moment(date))}
                disabledDate={d => !d || d.isBefore(startDate)}
                format={'YYYY-MM-DD'}
                placeholder='Fecha - Hora hasta incidente'
                rules={[{ required: true, message: 'Fecha hasta es requerida.' }]}
              />
            </>
            <Button type='primary' onClick={handleSearch}>Buscar</Button>
          </Space>
        ]}
      />
      <Table
        onChange={(_pagination, _filters, sorter) => {
          const sorterFn = sorter?.column?.sorter || (() => {});
          const order = sorter?.order;

          if(order) {
            setCurrentSorter(() => (prev, curr) => sorterFn(prev, curr) * (order === "ascend" ? 1 : -1));
          }
        }}
        className="table-custom"
        loading={loading}
        bordered={true}
        columns={columns}
        dataSource={estadisticas}
        scroll={{ y: 270 }}
        footer={
          () => (
            estadisticas.length ? 
              <Button 
                type="primary"
                onClick={handlePrint}
              >
                Imprimir
              </Button> : null
          )
        }
      />
    </div>
  )
}

export default AdminEstadisticas;
