const schema = {
  idTema: {
    type: "hidden",
    title: "#",
    dataIndex: "idTema"
  },
  descripcion: {
    type: "text",
    title: "Descripción",
    dataIndex: "descripcion",
    rules: [{
      required: true,
      message: "Ingrese descripción"
    }],
  },
};

export { schema };
