import Axios from "axios";

/**
 *
 * @type {string}
 */
const TOKEN_KEY = 'CRC_TOKEN';

/**
 *
 * @type {string}
 */
const KEY_F5 = '_F5';


/**
 * Guardar el Token
 * @param token
 */
export function setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
}

/**
 *
 * @param key
 */
export function setIdF5(key) {
    localStorage.setItem(KEY_F5, key);
}

/**
 * Leer el token
 */
export function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

export function getIdF5() {
    return localStorage.getItem(KEY_F5);
}

/**
 * Eliminar el Token
 */

export function deleteToken() {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem("TOKEN_KEY");
    localStorage.removeItem(KEY_F5);
    sessionStorage.removeItem("KEY_F5");
    sessionStorage.clear();
}


/**
 * Interceptar las llamadas en las peticiones
 * si existe un token se  agrega  en el http autorizacion
 * para que el servidor pueda reconocer el usuario
 */

export function initAxiosInterceptors() {
    Axios.interceptors.request.use(function (config) {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `${token}`;
            // config.headers.Authorization = `bearer ${token}`;
        }
        return config;
    });

    Axios.interceptors.response.use(
        function (response) {
            return response;

        },
        function (error) {
            if (error.response.status === 401) {
                deleteToken();
                
                //Antes de redirigir esperamos 3 segundos para leer el mensaje
                setTimeout(function () {
                   window.location = "/";
                }, 3000);


            } else {
                return Promise.reject(error);
            }
        }
    )


}






