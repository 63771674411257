import { useState, useEffect } from 'react';

import {
  Drawer,
  Form,
  Button,
} from 'antd';

import FormFields from './FormFields';

const DrawerForm = ({
  toggleDrawer,
  visible,
  handleSubmit,
  isNew,
  currentRecord,
  schema,
  entity,
  id = 'id'
}) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(`Crear ${entity}`);
  const [form] = Form.useForm();

  const onClose = () => {
    toggleDrawer();
  };

  useEffect(() => {
    form.resetFields();
    setTitle(`Crear ${entity}`);
    if (show !== visible) {
      setShow(visible);
    }
    if (!isNew) {
      form.setFieldsValue(currentRecord);
      setTitle(`Modificar ${entity} #${currentRecord[id]}`);
    }
  }, [form, entity, show, visible, isNew, currentRecord]);

  const onFinish = (fields) => {
    const formData = {
      ...Object.fromEntries(
        Object.entries(fields).filter(
          ([key, value]) => value !== currentRecord[key]
        )
      ),
    };
    handleSubmit(formData);
  };

  const handleReset = () => {
    form.resetFields();
    if(!isNew) {
      form.setFieldsValue(currentRecord);
    }
  };

  return (
    <>
      <Drawer
        title={title}
        width={720}
        onClose={onClose}
        visible={show}
        bodyStyle={{ paddingBottom: 80, button: { width: '5rem' } }}
        footer={
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem'
          }}>
            <Button onClick={handleReset}>
              Reestablecer
            </Button>
            <Button type="primary" onClick={form.submit}>
              Guardar
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
        >
          <FormFields schema={schema} />
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerForm;