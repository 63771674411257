import { calculateDuration, dateTimeString, sorterFn } from '../utils';

const dateTimeRender = (dateMilliseconds) => dateTimeString(dateMilliseconds);

const schema = {
  usuario: {
    type: "text",
    title: "Usuario",
    dataIndex: "usuario",
    sorter: (prev, curr) => sorterFn(prev, curr, "usuario"),
  },
  estado: {
    type: "text",
    title: "Estado",
    dataIndex: "estado",
    sorter: (prev, curr) => sorterFn(prev, curr, "estado"),
  },
  tipo: {
    type: "text",
    title: "Tipo",
    dataIndex: "tipo",
    sorter: (prev, curr) => sorterFn(prev, curr, "tipo"),
  },
  fechaInicial: {
    type: "text",
    title: "Fecha inicio",
    dataIndex: "fechaInicial",
    sorter: (prev, curr) => sorterFn(prev, curr, "fechaInicial"),
    render: dateTimeRender
  },
  fechaFinal: {
    type: "text",
    title: "Fecha fin",
    dataIndex: "fechaFinal",
    sorter: (prev, curr) => sorterFn(prev, curr, "fechaFinal"),
    render: dateTimeRender
  },
  tiempo: {
    "title": "Tiempo",
    "key": "time",
    render: estadistica => calculateDuration(estadistica.fechaInicial, estadistica.fechaFinal),
    sorter: (prev, curr) => sorterFn(
      prev.fechaFinal - prev.fechaInicial,
      curr.fechaFinal - curr.fechaInicial,
    ),
  },
  detalle: {
    type: "text",
    title: "Detalle",
    dataIndex: "detalle",
    sorter: (prev, curr) => sorterFn(prev, curr, "detalle"),
  },
};

export { schema };
