import React, { memo } from 'react';
import ReactNotification from "react-notifications-component";
import Fot from "../Vistas/Footer";

import TabSwitcher from '../components/TabSwitcher';
import {
  Estadisticas,
  ResultadosIndexacion,
  Temas,
} from '../Vistas/Admin';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Administracion = () => {



  return (
    <>
      <ReactNotification></ReactNotification>

      <div className="font-sans bg-grey-lighter flex flex-col min-h-screen w-full mt-20">
        <div className="md:container md:mx-auto bg-gray-100">
          <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8 ">
            <div
              className="border-azul bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mb-6 mt-16">
              <div className="w-auto mr-10 text-center mt-2">
                <h3 className="pb-2 border-b mb-3 text-sm font-semibold">Administración</h3>

              </div>

              <div>
              <TabSwitcher 
                tabs={[
                  {
                    component: <Estadisticas />,
                    name: 'Estadísticas'
                  },
                  {
                    component: <ResultadosIndexacion />,
                    name: 'Resultados Indexación'
                  },
                  {
                    component: <Temas />,
                    name: 'Temas'
                  },
                ]}
              />
              </div>

            </div>

          </div>
        </div>
      </div>

      <Fot />

    </>
  )

}

export default memo(Administracion)
