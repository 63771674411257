import pdfMake from "pdfmake/build/pdfmake"; // client-side

import crc_logo from '../assets/svg/crc_logo';

import { dateTimeString } from "../utils";

const cellString = value => {
  if(value === "") return "-";

  return {
      null: "-",
      "undefined": "-"
  }[value] || value;
};

const horizontalRule = () => [
  { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 485, y2: 0, lineWidth: 1 }] },
];

const printPdf = ({ data, functionName, criteria = {} }) => {

  pdfMake.fonts = {
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    },
  };

  const criteriaTable = Object.keys(criteria).length ? [
    { text: 'Criterios de consulta', fontSize: 11 },
    {
      style: 'table',
      table: {
        body: [
          Object.keys(criteria).map(criterion => (
            [
              { text: `${criterion}\n`, bold: true },
              { text: cellString(criteria[criterion]), fontSize: 10 }
            ]
          ))
        ]
      },
      layout: 'headerLineOnly'
    },
    horizontalRule(),
    '\n'
] : [];

  const docDefinition = {
    footer: (currentPage, pageCount) => ({
      text: `${currentPage} de ${pageCount}`,
      alignment: 'right',
      margin: [0, 0, 20, 0]
    }),

    content: [
      {
        columns: [
          {
            stack: [
              { svg: crc_logo, width: 150 }
            ],
            alignment: 'left',
            width: '*'
          },
          {
            stack: [
              { text: 'Fecha de consulta: ' + dateTimeString(), fontSize: 8 },
              { text: functionName, style: 'simpleHeader' }
            ],
            alignment: 'right',
            width: 'auto',
            margin: [0, 20, 0, 0]
          },
        ],
      },
      '\n',
      criteriaTable,
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: Object.keys(data[0]).map(key => key === "#" ? "auto" : "*"),
          body: [
            Object.keys(data[0]).map(text => ({ text, style: 'tableHeader' })),
            ...data.map(item => Object.values(item))
          ]
        },
        layout: 'headerLineOnly'
      },
    ],
    pageMargins: [40, 65, 70, 30],
    styles: {
      table: {
        fontSize: 8,
        margin: [0, 5, 0, 15]
      },
      tableHeader: {
        fontSize: 10,
        color: 'black'
      },
      simpleHeader: {
        bold: true,
        fontSize: 13,
        color: 'black'
      }
    },
    defaultStyle: {
      font: 'Roboto'
    }
  };

  pdfMake.createPdf(docDefinition).open();
};

export default printPdf;