import React from 'react';
import {Route, Switch} from "react-router-dom";
import {
    Historia,
    Cargar,
    OCR,
    Dicc,
    Login,
    Buscar,
    Administracion
} from "../componentes";

//Nuevos componentes de las rutas del usuario que este autenticado
/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function LoginRoutes() {
    return (


        <Switch>
            <Route
                path="/historia" Sensitive={true}
                render={props => <Historia {...props} ></Historia>}
            />

            <Route
                path="/cargar" Sensitive={true}
                render={props => <Cargar {...props}></Cargar>}
            />
            <Route
                path="/ocr" Sensitive={true}
                render={props => <OCR {...props}></OCR>}
            />
            <Route
                path="/diccionario" Sensitive={true}
                render={props => <Dicc {...props}></Dicc>}
            />
            <Route
                path="/administracion" Sensitive={true}
                render={props => <Administracion {...props}></Administracion>}
            />
            <Route
                path="/" Sensitive={true} default
                render={props => <Buscar {...props} ></Buscar>}
            />
        </Switch>

    )

}

//Nuevos componentes de las rutas del usuario que este autenticado
/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function LoginRoutesBuscar() {
    return (


        <Switch>
            <Route
                path="/historia" Sensitive={true} default
                render={props => <Historia {...props} ></Historia>}
            />
            <Route
                path="/" Sensitive={true} default
                render={props => <Buscar {...props} ></Buscar>}
            />
        </Switch>

    )

}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function LoginRoutesCargar() {
    return (


        <Switch>
            <Route
                path="/historia" Sensitive={true}
                render={props => <Historia {...props}></Historia>}
            />
            <Route
                path="/ocr" Sensitive={true}
                render={props => <OCR {...props}></OCR>}
            />
            <Route
                path="/" Sensitive={true} default
                render={props => <Cargar {...props}></Cargar>}
            />
        </Switch>

    )

}


//Render las rutas cuando no esta autenticado
/**
 *
 * @param login
 * @returns {JSX.Element}
 * @constructor
 */
export function LogoutRoutes({login}) {
    return (
        <Switch>
            <Route
                path="/"
                exact
                render={props => <Login {...props} login={login}></Login>}
            />
        </Switch>
    )
}
