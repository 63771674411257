import React from 'react';
import './assets/App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {initAxiosInterceptors} from "./Helpers/auth-helpers";
import Nav from "./Vistas/Nav";
import {useUsuario, UsuarioProvider} from "./Context/usuario-context";
import {LoginRoutes, LoginRoutesBuscar, LoginRoutesCargar, LogoutRoutes} from "./routes/LoginRoutesGeneral"

//Apenas cargue el navegador llamamos esta funcion
initAxiosInterceptors();

/**
 *
 * @returns {JSX.Element}
 */
export default () => <UsuarioProvider>

    <App></App>
</UsuarioProvider>

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function App() {

    const {loginExitoso, login, logout, usuario} = useUsuario();

    return (
        <Router>
            <Nav usuario={usuario} logout={logout} login={login}/>
            {(() => {
		console.log("El usuario es ");
		console.log(usuario);
                switch (usuario) {
                    case '1':
                        return <LoginRoutes/>;
                        break;
                    case '2':
                        return <LoginRoutesBuscar/>;
                        break;
                    case '3':
                        return <LoginRoutesCargar/>;
                        break;
		    case '4':
                        return <LoginRoutes/>;
                        break;
                    default:
                        return <LogoutRoutes login={login}/>;
                }
            })()}

        </Router>

    );


}












