import { Form, Input, Select } from "antd";

const { Option } = Select;

const FormField = ({ name, property }) => {
  return property.type !== "hidden" ?
  (
    <Form.Item
      name={name}
      label={property.title}
      rules={property.rules}
    >{

      property.type === "text" ? 
      <Input placeholder={property.placeholder} />
      :

      property.type === "select" ? (
      <Select mode={property.mode} placeholder={property.placeholder}>{

        Object.keys(property.options).map(key => {
          const title = property.options[key];
          let id = key;
          if(property.parse) {
            try {
              id = JSON.parse(key);
            } catch {
              id = key;
            }
          }

          return (
            <Option key={key} value={id} title={title}>
              {title}
            </Option>
          );
        })
        
      }</Select>

      ) : null

    }</Form.Item>
  )
  : null
}

const FormFields = ({ schema }) => {

  return (
    <div>{

      Object.keys(schema).map(key => (

        <FormField name={key} property={schema[key]} key={key} />

      ))

    }</div>

  );
};

export default FormFields;
