import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import printPdf from "../../services/adminPdf";

import { dateTimeString, dateString, notify } from '../../utils';

import { schema } from '../../models/ResultadoIndexacion';

import {
  Button,
  DatePicker,
  PageHeader,
  Space,
  Table,
} from 'antd';

const AdminResultadosIndexacion = () => {

  const [resultadosIndexacion, setResultadosIndexacion] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(moment().subtract(7, 'd'));
  const [endDate, setEndDate] = useState(moment());
  const [tableDates, setTableDates] = useState();
  const [currentSorter, setCurrentSorter] = useState(() => {});

  const handleFechaDesdeChange = (_, date) => {
    setEndDate(undefined);
    setStartDate(moment(date));
  };

  const handleSearch = () => {
    cargarResultadosIndexacion(startDate, endDate);
  };

  const cargarResultadosIndexacion = async (
    startDate,
    endDate
  ) => {

    if (!(startDate && endDate)) {
      notify("error", "Formato de fechas inválido.");

      return;
    }

    setLoading(true);
    try {
      let _startDate = dateString(startDate);
      let _endDate = dateString(endDate.add(1,'d'));

      const response = await axios.get(`api/indexation/results?start_date=${_startDate}&end_date=${_endDate}`);
      let _resp = response.data.sort(function(a, b) {
        if(a.fechaInicio > b.fechaInicio){
          return -1
        }
      });
    
      
      setResultadosIndexacion(_resp || []);
      if (!response.data.length) {
        notify("warning", "Sin resultados");
      } else {
        setTableDates({
          startDate: dateString(startDate),
          endDate: dateString(endDate),
        });
      }
    } catch (err) {
      console.error(err);
      notify("error", "No se pudieron obtener resultados de indexación.")
    }
    setLoading(false);
  };

  const handlePrint = () => {

    printPdf({
      data: resultadosIndexacion.sort(currentSorter).map(item => ({
        "Nombre de archivo": item.nombreArchivo,
        "Proceso OK": item.procesoOk,
        "Descripción": item.descripcion,
        "Fecha inicio": dateString(item.fechaInicio),
        "Fecha fin": dateString(item.fechaFin),
      })),
      functionName: "Resultados de indexación",
      criteria: {
        "Fecha inicio": tableDates.startDate,
        "Fecha fin": tableDates.endDate
      }
    });
  };


  useEffect(() => {
    cargarResultadosIndexacion(startDate, endDate);
  }, []);

  const columns = [
    ...Object.values(schema),
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Space>
            <>
              Fecha inicio:
              <DatePicker
                allowClear={false}
                value={startDate}
                onChange={handleFechaDesdeChange}
                format={'YYYY-MM-DD'}
                placeholder='Fecha - Hora'
                rules={[{ required: true, message: 'Fecha desde es requerida.' }]}
              />
              Fecha fin:
              <DatePicker
                allowClear={false}
                value={endDate}
                onChange={(_, date) => setEndDate(moment(date))}
                disabledDate={d => !d || d.isBefore(startDate)}
                format={'YYYY-MM-DD'}
                placeholder='Fecha - Hora hasta incidente'
                rules={[{ required: true, message: 'Fecha hasta es requerida.' }]}
              />
            </>
            <Button type='primary' onClick={handleSearch}>Buscar</Button>
          </Space>
        ]}
      />
      <Table
        onChange={(_pagination, _filters, sorter) => {
          const sorterFn = sorter?.column?.sorter || (() => {});
          const order = sorter?.order;

          if(order) {
            setCurrentSorter(() => (prev, curr) => sorterFn(prev, curr) * (order === "ascend" ? 1 : -1));
          }
        }}
        className="table-custom"
        loading={loading}
        bordered={true}
        columns={columns}
        dataSource={resultadosIndexacion}
        scroll={{ y: 270 }}
        footer={
          () => (
            resultadosIndexacion.length ? 
              <Button 
                type="primary"
                onClick={handlePrint}
              >
                Imprimir
              </Button> : null
          )
        }
      />
    </div>
  )
}

export default AdminResultadosIndexacion;
