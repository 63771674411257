import React, {memo, useEffect, useState} from 'react'
import Axios from "axios";
import '../assets/main.css';
import '../assets/App.css'
import ReactNotification, {store} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import Fot from "../Vistas/Footer";
import 'animate.css';

/**
 * Componente para Buscar
 * @param mostrarError
 * @param usuario
 * @returns {JSX.Element}
 * @constructor
 */
const Buscar = () => {
    const [temasSubTemasEntradas, setTemasSubTemasEntradas] = useState([{
        tema: '',
        subtema: []
    },]);

    /**
     * Evitamos que el usuario le de multiples veces al botón
     */
    const [cargandoDatosEnvio, setCargandoDatosEnvio] = useState(false);


    /**
     * Estado para obtener el valor digitado
     */
    const [palabra, setPalabra] = useState('');


    const [tema, setTema] = useState([]);

    const [subtemas, setSubtemas] = useState([]);

    const [subtemasBandeja, setSubtemasBandeja] = useState(false);

    const [cargarListTemas, setCargarListTemas] = useState(false);

    const [valorTemaSelecionado, setValorTemaSelecionado] = useState('');
    const [valorTemaSubSelecionado, setValorTemaSubSelecionado] = useState([]);
    const [mostrarTabla, setMostrarTabla] = useState(false);


    const [enviandoPeticion, setenviandoPeticion] = useState(false);
    /**
     * Estado para el archivo subido
     * @param e
     */
    const [archivo, setArchivo] = useState('');
    const [archivoNombre, setArchivoNombre] = useState('');
    const [archivotype, setArchivotype] = useState('');
    const [temaSelecionado, settemaSelecionado] = useState([]);

    /**
     *
     */
    useEffect(() => {
        async function cargarTemasIniciales() {
            try {
                const nuevosTemas = await cargarTemas() || {};

                setTema(nuevosTemas);
                clearInputs();
            } catch (error) {
            }
        }
        cargarTemasIniciales();
    }, []);

    /**
     *
     * @returns {Promise<any>}
     */
    async function cargarTemas() {
        const {data} = await Axios.get("api/consultar/temas");
        
        if (data.temas.length) {
            // Solo se incluye "Todos los documentos"
            const temas = data.temas.filter(
                tema =>
                    tema?.idTema === 1 ||
                    /Todos los documentos/gi.test(tema?.descripcion || "")
            );

            data.temas = temas;
        }
        
        return data;
    }


    /**
     *
     * @param e
     * @returns {Promise<void>}
     */

     function cargarSubTemas(e) {
        e.preventDefault();

        const idSubtema = e.target.value;

        const temaSeleciona = tema.temas.filter(element => element.idTema == idSubtema);
        const temaSelecionaDescripcion = temaSeleciona.map((temass) => (
            temass.descripcion
        ));
        setValorTemaSelecionado(temaSelecionaDescripcion)
    }

    /**
     *
     * @param event
     */
    const handleChangeSubtemas = (event) => {
        event.preventDefault();

        const idSubtema = event.target.value;
        setValorTemaSubSelecionado(idSubtema);

    }

    /**
     *
     * @param e
     * @returns {Promise<void>}
     */
    const onChangeArchivo = async e => {
        var arrayAuxiliar = [];
        const archivoByte = e.target.files[0];
        setArchivotype(archivoByte);
        const base64 = await convertirBase64(archivoByte);
        arrayAuxiliar = base64.split(',');
        const nombreArchivo = archivoByte.name;
        setArchivo(arrayAuxiliar[1]);
        setArchivoNombre(nombreArchivo);
        e.target.value = '';

    };

    /**
     * Función anonima que nos permite
     * convertir un archivo a Base64
     * @param e
     * @returns {Promise<void>}
     */
    const convertirBase64 = (archivo) => {
        return new Promise((resolve, reject) => {


            const archivoLeido = new FileReader();
            archivoLeido.readAsDataURL(archivo);
            archivoLeido.onload = (() => {
                resolve(archivoLeido.result);
            });
            archivoLeido.onerror = ((error) => {
                reject(error);
            });

        });


    }
    /**
     *
     */
    const clearInputs = () => {
        setArchivo('');
        setArchivoNombre('');
        setArchivotype('');
        setPalabra('');
        handleLimpiarCampos();
    }

    /**
     * Enviar Data
     */

    async function handleEnviandoConsulta(evento) {
        evento.preventDefault();
        if (cargandoDatosEnvio) {
            return;
        }
        //Object.entries(valorTemaSelecionado).length  === 0 palabra != ''
        try {
            const tieneArchivo = (archivoNombre !== '');
            // const tieneTemas = (Object.entries(temasSubTemasEntradas).length > 0);
            const tieneTemas = temasSubTemasEntradas.some(elementos => !(elementos.tema === ''));
            const tienePalabra = (palabra !== '');
            const esPosible = (tieneArchivo && !tienePalabra && tieneTemas) || (!tieneArchivo && tienePalabra && !tieneTemas) || (!tieneArchivo && !tienePalabra && tieneTemas);
            if (!tieneArchivo && !tieneTemas && !tienePalabra) {
                const notificationDatosNulos = {
                    title: "Aviso!",
                    message: "Parámetros de Búsqueda Vacíos.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };
                store.addNotification({
                    ...notificationDatosNulos,
                    dismiss: {
                        duration: 20000,
                        onScreen: true
                    }
                });
                setCargandoDatosEnvio(false);


            } else if (!esPosible) {
                const notificationArchivoTemas = {
                    title: "Aviso!",
                    message: "Debe enviar un tipo de búsqueda válido: ·····1. Palabra Digitada····· 2. Archivo + Temas······ 3. Temas",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes

                }
                store.addNotification({
                    ...notificationArchivoTemas,
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
                setCargandoDatosEnvio(false);
            } else if (archivoNombre !== '' && archivo !== '' && !(temasSubTemasEntradas.some(element => element.tema == ''))) {
                const datosCompuesta = {
                    'fileName': archivoNombre,
                    'file': archivo,
                    'temas': temasSubTemasEntradas,

                }
                setCargandoDatosEnvio(true);
                const {data} = await Axios.post('api/buscar', datosCompuesta);
                setCargandoDatosEnvio(false);
                const notificationExitosa = {
                    title: "Exitoso!",
                    message: data.estado.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };
                store.addNotification({
                    ...notificationExitosa,
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });

                setCargandoDatosEnvio(false);
                clearInputs();
            } else if (palabra !== '') {
                const soloPalabra = {
                    'query': palabra,
                }
                setCargandoDatosEnvio(true);
                const {data} = await Axios.post('api/buscar', soloPalabra);
                setCargandoDatosEnvio(false);
                const notificationExitosa = {
                    title: "Exitoso!",
                    message: data.estado.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };


                store.addNotification({
                    ...notificationExitosa,
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
                clearInputs();
            } else {
                // const datosCompuesta = {
                // 	'query': palabra,
                //     'fileName': archivoNombre,
                //     'file': archivo,
                //     'temas': temasSubTemasEntradas,
                //
                // }
                const datosCompuesta = {
                    'temas': temasSubTemasEntradas,

                }
                setCargandoDatosEnvio(true);
                const {data} = await Axios.post('api/buscar', datosCompuesta);
                setCargandoDatosEnvio(false);
                const notificationExitosa = {
                    title: "Exitoso!",
                    message: data.estado.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };


                store.addNotification({
                    ...notificationExitosa,
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
                clearInputs();
            }
        } catch (error) {
            console.log(error);
            let mensajeErro = '';
            if (error.response && (error.response.status === 404 || error.response.status === 400)) {
                mensajeErro = 'Búsqueda (003) - Debe enviar un tipo de búsqueda válido: \n    1. Palabra Digitada \n   2. Archivo + Temas \n    3. Temas.';
            } else {
                mensajeErro = 'Se presento un error enviando la información. Intente nuevamente ';
            }
            const notificationError = {
                title: "Error!",
                message: mensajeErro,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
            };
            store.addNotification({
                ...notificationError,
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            setCargandoDatosEnvio(false);
            clearInputs();

        }
    }


    /**
     * Tabla de busquedas
     */

    const handleChangeInput = (index, event) => {
        const valores = [...temasSubTemasEntradas];
        valores[index][event.target.name] = event.target.value;
        setTemasSubTemasEntradas(valores);
    }
    const handleAddCampos = () => {
        if (temasSubTemasEntradas.length > 0) {
            const notification = {
                title: "Aviso!",
                message: 'El máximo de temas seleccionados son : 1',
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
            };


            store.addNotification({
                ...notification,
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            return;
        }
        if (valorTemaSelecionado === ""  || Object.entries(valorTemaSelecionado).length === 0) {
            const notification = {
                title: "Aviso!",
                message: 'Para agregar un tema debe seleccionarlos',
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
            };


            store.addNotification({
                ...notification,
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        } else {
            temasSubTemasEntradas.reduce(function (contador, item) {
                if (item.tema === '' && Object.entries(item.subtema).length === 0) {
                    const removed = temasSubTemasEntradas.splice(contador, 1);
                    return setTemasSubTemasEntradas(removed);
                }
            }, 0);


            setTemasSubTemasEntradas([...temasSubTemasEntradas, {
                tema: valorTemaSelecionado[0],
                subtema: [valorTemaSubSelecionado]
            }]);
            setCargarListTemas(true);
            setMostrarTabla(true);
        }


    }
    const handleEliminarCampos = (idx) => {
        const valores = [...temasSubTemasEntradas];
        valores.splice(idx, 1);
        setTemasSubTemasEntradas(valores);
        if (idx === 0 && temasSubTemasEntradas.length === 1) {
            setMostrarTabla(false);

        }
    }

    const handleLimpiarCampos = () => {
        const valores = [...temasSubTemasEntradas];
        valores.splice(0, valores.length);
        setTemasSubTemasEntradas(valores);
        setMostrarTabla(false);
    }


    return (
        <form onSubmit={handleEnviandoConsulta}>
            <ReactNotification></ReactNotification>


            <div className="font-sans bg-grey-lighter flex flex-col min-h-screen w-full mt-20 ">
                <div className="md:container md:mx-auto bg-gray-100">
                    <div className="flex-grow container mx-auto sm:px-4 pb-8 py-6">
                        <div
                            className=" border-azul bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mb-6 mt-16">
                            <div className="w-auto text-center mt-2">
                                <h3 className="pb-2 border-b mb-3 text-sm font-semibold">
                                    Seleccione un archivo para compararlo contra la base de conocimiento
                                </h3>
                            </div>
                            <div className="flex justify-center">
                                <div className="w-1/3 text-center py-4">
                                    <div className="border-r">
                                        <div className="text-grey-darker mb-2">
                                            <div className="p-0.5">
                                                <div
                                                    className="ml-6 bg-white h-10 flex items-center rounded-full shadow-lg border border-rosa cursor-pointer">
                                                    <input value={archivoNombre} readOnly disabled
                                                           className="rounded-l-full appearance-none bg-transparent w-96 py-4 px-6  h-8 text-black leading-tight focus:outline-none"
                                                           onChange={onChangeArchivo}/>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-sm">
                                            Nombre del Archivo
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/3 text-center py-4">
                                    <div>
                                        <div className="text-grey-darker mb-2">
                                            <div className="rounded-full w-50">
                                                <label

                                                    className="ml-0 h-10 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-rosa hover:bg-azul">
                                                    <svg className="w-8 h-8" fill="currentColor"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 20 20">
                                                        <path
                                                            d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"/>
                                                    </svg>
                                                    <span
                                                        className="px-2 text-sm text-center">Seleccionar archivo</span>
                                                    <input accept=".doc,.docx,.txt" type='file' className="hidden"
                                                           onChange={onChangeArchivo}/>
                                                </label>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-4">
                            <div className=" w-full mb-6 lg:mb-0 lg:w-1/2 h-2/6 px-4 ">
                                <div
                                    className="border-azul flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden">

                                    <div className="flex justify-between px-6 -mt-1 -mb-px">
                                        <h3 className="text-blue-dark py-4 font-semibold text-sm">Tema:</h3>
                                    </div>


                                    <div
                                        className="flex-grow flex gap-5 px-6 py-6 text-grey-darker items-center border-b -mt-8">
                                            {
                                                tema.temas && (
                                                    <select name="tema"
                                                            className="w-auto h-10  rounded-lg shadow-lg  border border-rosa cursor-pointer hover:text-azul"
                                                            onChange={cargarSubTemas}>
                                                        <option defaultChecked={true}
                                                                value="Seleccione..."
                                                                defaultValue="Seleccione...">Seleccione...
                                                        </option>
                                                        {tema.temas.map((tema) => (
                                                            <option key={tema.idTema}
                                                                    value={tema.idTema}>{tema.descripcion}</option>
                                                        ))}
                                                    </select>


                                                )
                                            }

                                            <div className="border-rosa cursor-pointer hover:text-white">
                                                <div className="flex justify-center">
                                                    <label
                                                        className="ml-0 h-10 whitespace-nowrap inline-flex items-center justify-center px-2 rounded-md shadow-sm text-base font-medium text-white bg-rosa hover:bg-azul">
                                                        <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd"
                                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                                                  clipRule="evenodd"/>
                                                        </svg>
                                                        <span className="px-2 text-sm">Seleccionar</span>
                                                        <input type='button' className="hidden"
                                                               onClick={() => handleAddCampos()}/>
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                </div>
                            </div>
                            <div className="w-full lg:w-1/2 px-4">
                                <div className="border-azul bg-white border-t border-b sm:rounded sm:border shadow">
                                    <div className="flex justify-between px-6 -mt-1 -mb-px">
                                        <h3 className="text-blue-dark py-4 font-semibold text-sm">Tema
                                            Seleccionado:</h3>
                                    </div>
                                    <div>
                                        <div className="text-center px-6">

                                            {
                                                mostrarTabla ? (
                                                    <div className="container mt-0 ml-24">
                                                        <table
                                                            className="md:table table-bordered table-hover"
                                                            id="tab_logic">
                                                            <thead>

                                                            </thead>
                                                            <thead>
                                                            <tr>
                                                                <th className="px-1 text-center bg-gray-100 text-left text-sm font-semibold text-gray-600 tracking-wider"> #</th>
                                                                <th className="px-1 text-center bg-gray-100 text-left text-sn font-semibold text-gray-600 tracking-wider"> Tema</th>
                                                                {/*<th className="px-1 text-center bg-gray-100 text-left text-sm font-semibold text-gray-600 tracking-wider"> Subtema</th>*/}
                                                                <th className="px-1 text-center bg-gray-100 text-left text-sm font-semibold text-gray-600 tracking-wider"> Opción</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="mr-32 text-center">
                                                            {cargarListTemas &&
                                                            temasSubTemasEntradas.map((item, idx) => (
                                                                <tr id="addr0" key={idx}>
                                                                    <td>{idx}</td>
                                                                    <td className="px-5 py-2 text-sm">
                                                                        <input
                                                                            className="rounded-l-md w-36 h-10 bg-transparent rounded-lg shadow-lg text-center border border-rosa cursor-pointer hover:text-azul"
                                                                            type="text"
                                                                            name="tema"
                                                                            disabled
                                                                            value={item.tema}
                                                                            onChange={event => handleChangeInput(idx, event)}
                                                                        />
                                                                    </td>
                                                                    {/*<td className="px-5 text-sm">*/}
                                                                    {/*    <input*/}
                                                                    {/*        type="text"*/}
                                                                    {/*        className="rounded-l-md w-36 h-10 rounded-lg shadow-lg  text-center border border-rosa cursor-pointer hover:text-azul"*/}
                                                                    {/*        name="subtema"*/}
                                                                    {/*        disabled*/}
                                                                    {/*        value={item.subtema}*/}
                                                                    {/*        onChange={event => handleChangeInput(idx, event)}*/}
                                                                    {/*    />*/}
                                                                    {/*</td>*/}
                                                                    <td className="px-5 text-sm">
                                                                        <label
                                                                            className="ml-0 h-10 whitespace-nowrap inline-flex items-center justify-center px-2 rounded-md shadow-sm text-base font-medium text-white bg-rosa hover:bg-azul">
                                                                            <svg className="h-7 w-7"
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 viewBox="0 0 20 20"
                                                                                 fill="currentColor">
                                                                                <path fillRule="evenodd"
                                                                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                                                                                      clipRule="evenodd"/>
                                                                            </svg>
                                                                            <span
                                                                                className="px-2 text-sm">Eliminar</span>
                                                                            <input type='button' className="hidden"
                                                                                   onClick={(i) => handleEliminarCampos(idx)}/>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            ))

                                                            }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : null

                                            }

                                        </div>

                                    </div>

                                </div>
                                {/**/}
                                <div className="hidden lg:flex ml-12 -top-2.5 justify-center">
                                    <div className="w-1/3 text-right -ml-9 py-4">

                                        <div className="border-r">

                                            <label

                                                className="whitespace-nowrap inline-flex items-center h-11 justify-center px-4 rounded-md shadow-sm text-sm text-white bg-azul hover:bg-rosa">
                                                <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                     viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                          stroke-width="2"
                                                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                                </svg>
                                                <span className="px-2 text-sm text-center"> Limpiar</span>
                                                <input type='button' className="hidden"
                                                       onClick={clearInputs}/>
                                            </label>


                                        </div>
                                    </div>
                                    <div className="w-1/3 text-center py-4">
                                        <div>
                                            <div className="text-grey-darker mb-2">
                                                <div className="rounded-full w-50 mr-60 px-8 py-0">
                                                    <label
                                                        className="h-11 whitespace-nowrap inline-flex items-center px-2 rounded-md shadow-sm text-base font-medium text-white bg-azul hover:bg-rosa">


                                                        {
                                                            !cargandoDatosEnvio && (
                                                                <button>
                                                                    <svg className="h-9 w-9"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 20 20"
                                                                         fill="currentColor">
                                                                        <path fill-rule="evenodd"
                                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                                                              clip-rule="evenodd"/>
                                                                    </svg>
                                                                </button>)
                                                        }

                                                        <span className="px-4 text-sm">Comparar</span>

                                                        {
                                                            cargandoDatosEnvio && (
                                                                <svg
                                                                    className="animate-spin h-6 w-6 text-center mt-8 ml-6 "
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          strokeWidth={2}
                                                                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                                                                </svg>
                                                            )
                                                        }
                                                    </label>


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/**/}

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Fot/>

        </form>


    )


}


export default memo(Buscar)
