import React, { useState, useEffect } from 'react';
import axios from 'axios';

import printPdf from "../../services/adminPdf";

import {schema} from '../../models/Tema';

import {
  Button,
  Input,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  message
} from 'antd';


import { FiX, FiEdit2, FiSearch } from 'react-icons/fi';

import DrawerForm from '../../components/DrawerForm';

const iconOptions = {
  size: '1.25rem',
  strokeWidth: 1
};

const AdminTemas = () => {

  const [temas, setTemas] = useState([]);
  const [selectedTema, setSelectedTema] = useState({});
  const [sourcedata, setSourcedata] = useState({});
  const [filterApplied, setFilterApplied] = useState(false);
  const [loading, setLoading] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const cargarTemas = async () => {
    setLoading(true);
    try {
      const response = await axios.get('api/temas/temas');
      setFilterApplied(false);
      setSourcedata(response.data);
      setTemas(response.data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const toggleDrawer = () => {
    setDrawerVisible(prevValue => !prevValue);
 }

  const handleSubmit = async (values) => {
   const actionInfo = isNew
      ? {
        key: "TEMA_CREATE",
        loading: "Creando tema",
        error: "Error al crear tema",
        success: "Tema creado",
      }
      : {
        key: "TEMA_UPDATE",
        loading: "Modificando tema",
        error: "Error al modificar tema",
        success: "Tema modificado",
      };

    message.loading({
      content: actionInfo.loading,
      key: actionInfo.key
    });

    const service = isNew
      ? { send: axios.post, url: "api/temas/temas" }
      : { send: axios.put, url: `api/temas/temas/${selectedTema.idTema}` };

    await service
      .send(service.url, values)
      .then(() => {
        message.success({
          content: actionInfo.success,
          key: actionInfo.key,
        });

        cargarTemas();
        toggleDrawer();
      })
      .catch(() => {
        message.error({
          content: actionInfo.error,
          key: actionInfo.key,
        });
      });
  };


  const handleNew = () => {
    setSelectedTema({});
    setIsNew(true);
    toggleDrawer();
  };

  const handleEdit = tema => {
    setSelectedTema(tema);
    setIsNew(false);
    toggleDrawer();
  };

  const handleDelete = async (idTema) => {
    message.loading({
      content: 'Borrando tema...',
      key: 'TEMA_DELETE'
    });

    await axios.delete(
      `api/temas/temas/${idTema}`,
    ).then(() => {
      message.success({
        content: 'Tema borrado',
        key: 'TEMA_DELETE'
      });

      cargarTemas();
    })
    .catch(() => {
      message.error({
        content: 'Error al borrar tema',
        key: 'TEMA_DELETE'
      });
    });
  };

  const handleSearch = e => {
    const search = e.target.value.trim().toLowerCase();

    if (!search) {
      setFilterApplied(false);
      setTemas(sourcedata);
      return;
    }

    setFilterApplied(true);
    setTemas(sourcedata.filter(tema => tema.descripcion.toLowerCase().includes(search)));
  };
  
  const handlePrint = () => {
    const reportData = temas.map(({idTema, descripcion}) => ({"#": idTema, "Descripción": descripcion}));

    printPdf({
      data: reportData,
      functionName: "Temas"
    });
  };

  useEffect(() => {
    cargarTemas();
  }, []);

  const columns = [
    ...Object.values(schema),
    {
      align: 'center',
      title: 'Acciones',
      key: 'action',
      width: '6rem',
      render: (tema) => (
        <Space size="middle">

          <Button
            onClick={() => handleEdit(tema)}
            type='link'
            icon={
              <FiEdit2 {...iconOptions} />
            }
          />
          <Popconfirm
            title="Confirmar eliminación"
            onConfirm={() => handleDelete(tema.idTema)}
            placement="left"
            okText="Eliminar"
            cancelText="Cancelar"
          >
            <Button type='link'
              icon={
                <FiX {...iconOptions} />
              }
            />
          </Popconfirm>
        </Space>
      )
    }
  ];

  columns[0] = {
    ...columns[0],
    width: '4rem',
    align: 'center'
  };

  return (
    <div>
      <PageHeader
            extra={[
               <Space>
                 {filterApplied && (
                   <Button onClick={() => {
                     setFilterApplied(false);
                     setTemas(sourcedata);
                   }}>Reiniciar búsqueda</Button>
                 )}
                <Input
                  prefix={<FiSearch/>}
                  placeholder='Buscar...'
                  onPressEnter={handleSearch}
                />
                <Button onClick={handleNew} type='primary'>Nuevo</Button>
               </Space>
            ]}
         />
      <Table
        className="table-custom"
        loading={loading}
        bordered={true}
        columns={columns}
        dataSource={temas}
        scroll={{ y: 270 }}
        footer={
          () => (
            temas.length ? 
              <Button 
                type="primary"
                onClick={handlePrint}
              >
                Imprimir
              </Button> : null
          )
        }
      />
     <DrawerForm
        visible={drawerVisible}
        toggleDrawer={toggleDrawer}
        handleSubmit={handleSubmit}
        isNew={isNew}
        currentRecord={selectedTema}
        schema={schema}
        entity="tema"
        id="idTema"
      />
    </div>
  )
}

export default AdminTemas;
