import { notification } from "antd";

import moment from "moment";

export const sortData = (data, property = null) => {
  if(!property) return data.slice().sort((a, b) => a[property] - b[property]);

  return data.slice().sort((a, b) => a - b);

};

export const dateFormat = "YYYY-MM-DD";
export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss"

export const dateString = (value = undefined) => moment(value).format(dateFormat);

export const dateTimeString = (value = undefined) => moment(value).format(dateTimeFormat);

export const notify = (type = "warning", description = "") => notification[type]({ description, placement: "bottomRight" });

export const calculateDuration = (date1, date2, format = true) => {
  const diffTime = moment(date2).diff(date1);

  if (!format) {
    return diffTime;
  }

  return moment(diffTime).utc().format("HH:mm:ss:SSS");
};

export const sorterFn = (prev, curr, entity) => {
  const previous = entity ? prev[entity] : prev;
  const current = entity ? curr[entity] : curr;

  if(typeof previous === "number") {
    return previous === current ? 0 : (previous - current) > 0 ? 1 : -1;
  }

  return String(previous).toLowerCase().localeCompare(String(current).toLowerCase());
};

const utils = {
  calculateDuration,
  dateFormat,
  dateTimeFormat,
  dateString,
  dateTimeString,
  notify,
  sortData,
  sorterFn,
};

export default utils;