import React, {memo, useState} from 'react';
import '../assets/main.css';
import '../assets/App.css'
import logoAnalisa from '../assets/img/_analisa3.png';
import facebook from '../assets/iconos/facebook.png';
import youtube from '../assets/iconos/youtube.png';
import twwiter from '../assets/iconos/twitter.png';
import asistente from '../assets/iconos/logo_sofia.png';
import logo from '../assets/img/logindashboard.png';

import 'react-notifications-component/dist/theme.css';
import 'animate.css';

import {Alert} from 'antd';
import {NavLink} from 'react-router-dom'


/**
 *
 * @param login
 * @returns {JSX.Element}
 * @constructor
 */
function Login({login}) {


    const [userYPasword, setUserYPasword] = useState({
        user: '',
        password: '',

    });
    const [key, setKey] = useState(null);
    const [cargandoLogin, setCargandoLogin] = useState(false);


    //contener los errores
    const [error, setError] = useState(false);

    /**
     * Cuando cambia un valor en el formulario
     * @param e
     */
    function handleInputChange(e) {
        setUserYPasword({
            ...userYPasword,
            [e.target.name]: e.target.value
        });
    }

    /**
     * Cuando se envie el formulario
     * llamamos esta funcion
     * Asincrona
     * @param e
     */
    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setCargandoLogin(true);
            await login(userYPasword.user, userYPasword.password);
            setCargandoLogin(false);
        } catch (error) {
            setError(true)

        }
    }

    return (
        <section className="login fondoLogin">
            {
                error ? (
                    <Alert
                        message="Usuario o contraseña incorrectos. Verifique los datos, asegurándose de estar registrado e intente nuevamente"
                        type="error" showIcon/>
                ) : null

            }

            <div class="flex">
                <div class="flex justify-center w-1/2">
                    <img className="h-20 w-auto" src={logo}/>
                </div>

                <div class="flex justify-center flex-row space-x-2 space-x w-1/2 py-4">
                    <div>
                        <NavLink activeClassName="false" to={{pathname: "https://www.facebook.com/CRCCol"}}
                                 target="_blank">
                            <img src={facebook} alt="Facebook CRC"/>
                        </NavLink>
                    </div>
                    <div>
                        <NavLink activeClassName="false" to={{pathname: "https://twitter.com/CRCCol"}} target="_blank">
                            <img src={twwiter} alt="Twitter CRC"/>
                        </NavLink>
                    </div>
                    <div>
                        <NavLink activeClassName="false" to={{pathname: "https://www.youtube.com/user/CRCCol"}}
                                 target="_blank">
                            <img src={youtube} alt="Youtube CRC"/>
                        </NavLink>
                    </div>
                    <div>
                        <NavLink activeClassName="false" to={{pathname: "https://www.crcom.gov.co/es/pagina/inicio"}}
                                 target="_blank">
                            <img src={asistente} alt="Asistente Virtual"/>
                        </NavLink>
                    </div>

                    <div class="px-4"></div>

                </div>
            </div>


            <div className="loginContainer">
                <img src={logoAnalisa}/>

                <form onSubmit={handleSubmit}>
                    <label>Usuario <span className="text-rosa text-sm">*</span></label>
                    <input className="focus:ring-2 focus:ring-morado text-sm" type="text" name="user" autoFocus required
                           value={userYPasword.user}
                           onChange={handleInputChange}/>
                    <label>Contraseña <span className="text-rosa text-sm">*</span></label>
                    <input className="focus:ring-2 focus:ring-morado text-sm" type="password"
                           name="password"
                           required onChange={handleInputChange} value={userYPasword.password}/>

                    <div className="btnContainer transform hover:bg-opacity-25 transition duration-200 hover:scale-110">
                        {
                            !cargandoLogin && (
                                <button type="submit"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-base 2xl:font-medium rounded-md text-white bg-gris hover:bg-azul focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-azul">
                              <span className="absolute left-0 inset-y-0 flex items-center pl-3">

                                <svg className="h-5 w-5 text-rosa group-hover:text-rosa"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd"
                                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                          clip-rule="evenodd"/>
                                </svg>
                              </span>
                                    Ingresar
                                </button>
                            )
                        }
                        {
                            cargandoLogin && (
                                <div className="spinner">
                                    <div></div>
                                    <div></div>
                                </div>
                            )
                        }
                    </div>
                </form>
                <h1 className="text-center mt-20 text-rosa">Versión 2.2.13</h1>
            </div>
        </section>
    )
}

export default memo(Login)
