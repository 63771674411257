import React, {memo, useEffect, useState} from 'react';
import '../assets/main.css';
import '../assets/App.css';
import Axios from "axios";
import ReactNotification, {store} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import {Button, ConfigProvider, Modal, Table} from "antd";
import "antd/dist/antd.css";
import espanol from 'antd/lib/locale/es_ES';
import {DownloadOutlined, FilePdfOutlined, PlusCircleOutlined, SearchOutlined} from '@ant-design/icons';
import Fot from "../Vistas/Footer";
import moment from 'moment'
import printPdf from '../services/adminPdf';
import { dateTimeString } from '../utils';


/**
 *
 * @param valor
 * @returns {JSX.Element}
 * @constructor
 */
function Icono({valor}) {
    switch (valor) {
        case 'search':
            return <SearchOutlined className="border-transparent border-2 p-2 hover:border-azul"
                                   style={{fontSize: '20px', color: '#3366CC'}}/>
        case 'upload':
            return <PlusCircleOutlined className="border-transparent border-2 p-2 hover:border-azul"
                                       style={{fontSize: '20px', color: '#3366CC'}}/>
        case 'ocr':
            return <FilePdfOutlined className="border-transparent border-2 p-2 hover:border-azul"
                                    style={{fontSize: '20px', color: '#3366CC'}}/>
        default:
            return <SearchOutlined className="border-transparent border-2 p-2 hover:border-azul"
                                   style={{fontSize: '20px', color: '#3366CC'}}/>
    }

}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Historia = () => {

    /**
     * Variables de Estado
     */
    const [cargandoHistoria, setCargandoHistoria] = useState(true);
    const [historias, setHistorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [historicoConsulta, setHistoricoConsulta] = useState([]);
    const [paginacionBottom, setPaginacionBottom] = useState({
        bottom: 'bottomCenter',
    });
    const [selectedHistoria, setSelectedHistoria] = useState({});

    /**
     *
     */
    useEffect(() => {
        async function cargarHistorias() {
            try {
                setCargandoHistoria(true);
                const {data} = await Axios.get('api/historia');
                setHistorias(data.data);
                setLoading(false);
                setCargandoHistoria(false);
            } catch (error) {
                if (error.response && (error.response.status === 404 || error.response.status === 400)) {
                    // mostrarError('Hubo un problema cargando las Historias.');
                } else {
                    // mostrarError('Hubo un problema cargando las Historias.');
                }
                setCargandoHistoria(false);

            }
        }

        cargarHistorias();
    }, []);


    /**
     *      * @param idUUID
     * @returns {Promise<void>}
     */
    async function buscarHistoria(idUUID) {
        try {
            const {data} = await Axios.get(`api/historia/${idUUID}`);
            setHistoricoConsulta(data.resultados);
            setVisible(true)
        } catch (error) {

        }
    }

    /**
     * Funcion que permite el extraer el nombre de
     * un documento con su extension
     * @param nombreArchivo
     * @returns {string}
     */
    function retornarPath(nombreArchivo) {
        if (nombreArchivo) {
            const startIndex = (nombreArchivo.indexOf('\\') >= 0 ? nombreArchivo.lastIndexOf('\\') : nombreArchivo.lastIndexOf('/'));
            let filename = nombreArchivo.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                return filename = filename.substring(1);
            }
        }
    }

    function handlePrint(mode) {
        if (mode === "General") {
            printPdf({
                data: historias.map(item => ({
                    "Archivo": item.name,
                    "Fecha": dateTimeString(item.time),
                    "Tiempo": item.when,
                })),
                functionName: "Historia - General"
            });
        } else if (mode === "Detalle") {
            let criteria = {};

            if (selectedHistoria.name) criteria["Archivo"] = selectedHistoria.name;
            if (selectedHistoria.time) criteria["Fecha"] = dateTimeString(selectedHistoria.time);

            printPdf({
                data: historicoConsulta.map(item => ({
                    "Nombre": item.nombre,
                    "Puntaje": item.puntaje,
                    "Fecha": dateTimeString(item.fecha)
                })),
                criteria,
                functionName: "Historia - Detalle"
            });
        }
    };


    /**
     * Funcion que permite consulta el documento y descargarlo
     * @param urlUno
     */
    function descargarDocumento(urlUno) {

        Axios.get(urlUno, {
            responseType: "blob",
        }).then((response) => {


            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                    type: response.headers["content-type"],
                })
            );

            const link = document.createElement('a');

            link.href = url;

            link.setAttribute('download', retornarPath(urlUno)); //any other extension

            document.body.appendChild(link);

            link.target = "_blank";

            link.click();

            link.remove();
        });

    }

    /**
     * Funcion que consulta la URL del documento
     * de acuerdo con su ID
     * @param idUUID
     * @returns {Promise<void>}
     */
    async function buscarHistoriaPDF(idUUID) {
        try {
            const {data} = await Axios.get(`api/historia/${idUUID}`);
            const urlDescarga = data.resultados.map((resultado) => (
                resultado.url
            ));
            console.log(urlDescarga)
            if (Object.entries(urlDescarga).length > 0) {
                const urlUno = urlDescarga[0];
                descargarDocumento(urlUno);

            } else {
                const notificationArchivoNoEncontrado = {
                    title: "Aviso!",
                    message: "Archivo no encontrado.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
                    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes
                };
                store.addNotification({
                    ...notificationArchivoNoEncontrado,
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {

        }
    }


    /**
     * Primera columna para los datos del historico
     * @type {({dataIndex: string, width: number, title: string, align: string, render: (function(*)), key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string})[]}
     */
    const columnas = [
        {
            title: "Acción",
            dataIndex: "type",
            key: "type",
            width: 100,
            align: "center",
            render: type => <Icono valor={type}></Icono>

        },
        {
            title: "Archivo",
            dataIndex: "name",
            key: "name",


        },
        {
            title: "Fecha",
            dataIndex: "time",
            key: "time",
            render: time => moment(time).format('YYYY-M-DD HH:mm:ss')

        },
        {
            title: "Tiempo",
            dataIndex: "when",
            key: "when",


        }

    ];

    /**
     * Columna para el PopUp
     * @type {({dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, key: string}|{dataIndex: string, title: string, render: (function(*)), key: string})[]}
     */
    const columnasResultado = [

        {
            title: "Nombre ",
            dataIndex: "nombre",
            key: "nombre",

        },
        {
            title: "Puntaje",
            dataIndex: "puntaje",
            key: "puntaje"
        }
        ,
        {
            title: "Fecha",
            dataIndex: "fecha",
            key: "fecha",
        },
        {
            title: "URL",
            dataIndex: "url",
            key: "url",
            render: url => <DownloadOutlined className="border-transparent border-2 p-2 hover:border-azul"
                                             style={{fontSize: '20px', color: '#3366CC'}}><p>Descargar Documento</p>
            </DownloadOutlined>

        },

    ];


    /**
     * Funcion para obtener el valor e identificador de la fial
     * @param record
     * @param rowIndex
     */
    const handleValorFila = (record, rowIndex) => {
        if (record.uuid !== null && record.type !== null && record.type === 'search') {
            setSelectedHistoria(record);
            buscarHistoria(record.uuid);
            setVisible(true)
        } else {
            setSelectedHistoria(record);
            buscarHistoriaPDF(record.uuid)
        }

    };

    /**
     * Consultar el documento para la fila selecionada
     * del PopUp
     * @param record
     * @param rowIndex
     */
    const handleValorTablaPopUp = (record, rowIndex) => {
        const urlArchivo = record.url;
        descargarDocumento(urlArchivo);

    };
    /**
     *
     * @param data
     * @returns {*}
     */
    const sortData = (data) => {
        // Call slice to create a new Array and prevent mutating it if it's stored in state
        return data.slice().sort((a, b) => a.uuid - b.uuid);
    }

    return (
        <ConfigProvider locale={espanol}>
            <ReactNotification></ReactNotification>
            <div className="font-sans bg-grey-lighter flex flex-col min-h-screen w-full mt-20">
                <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8 ">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden mt-16 bg-grisDos px-4">
                        <div className="w-auto mr-10 text-center mt-2">
                            <h3 className="text-sm font-semibold">Historia</h3>
                        </div>

                        <table>
                            <Table
                                rowKey="uuid"
                                bordered={true}
                                columns={columnas}
                                dataSource={sortData(historias)}
                                pagination={{position: [paginacionBottom.bottom]}}
                                scroll={{y: 270}}
                                loading={cargandoHistoria}
                                className="table-custom"
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            handleValorFila(record, rowIndex)
                                        },
                                    };
                                }}
                                footer={
                                    () => (
                                        historias.length ?
                                            <Button
                                                type="primary"
                                                onClick={() => { handlePrint("General") }}
                                            >
                                                Imprimir
                                            </Button> : null
                                    )
                                }

                            />,

                        </ table>


                        {
                            <>
                                <Modal
                                    title="Resultado"
                                    closable={false}
                                    visible={visible}
                                    centered
                                    onOk={() => setVisible(false)}
                                    onCancel={() => setVisible(false)}
                                    width={1000}
                                    wrapClassName="vertical-center-modal"


                                >


                                    <Table
                                        rowKey="uuid"
                                        bordered={true}
                                        columns={columnasResultado} dataSource={historicoConsulta}
                                        pagination={{ pageSize: 10 }} scroll={{ y: 370 }}
                                        className="table-custom"
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => {
                                                    handleValorTablaPopUp(record, rowIndex)
                                                },
                                            };
                                        }}
                                        footer={
                                            () => (
                                                historicoConsulta.length ?
                                                    <Button
                                                        type="primary"
                                                        onClick={() => { handlePrint("Detalle") }}
                                                    >
                                                        Imprimir
                                                    </Button> : null
                                            )
                                        }

                                    />

                                </Modal>
                            </>
                        }


                    </div>

                </div>

            </div>

            <Fot/>

        </ConfigProvider>

    )
}


export default memo(Historia)